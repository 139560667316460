import React, { Fragment, useEffect, useState,useCallback} from 'react';
import axios from 'axios';
import { TextField, Button, Card, CardContent, CardMedia, Typography, Link ,Stack,Container,Grid, Pagination  } from '@mui/material';
import './App.css';

const config = {
  BASE_URL: 'https://mindmap-backend.omnireach.in'
};

const App = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState('')
  const [alpha, setAlpha] = useState('0.5')
  const [processingEnabled,setProcessingEnabled] = useState(false)
  const [responseTime,setResponseTime] = useState('')
  const [currentPage,setCurrentPage] = useState(0)
  const [startIndex,setStartIndex] = useState(0)
  const [endIndex,setEndIndex] = useState(0)
  const [listening, setListening] = useState(false); // State for managing the animation
  const pageSize = 10; 

const categoryList = [
    { name: "Modern Classroom", code: "offer_modclass" },
    { name: "Playground Equipment", code: "playground_equipment" }, 
    { name: "Robotics and Coding", code: "offer_robotics" },
    { name: "Office Supplies", code: "office_supplies" },
    { name: "Construction Paper", code: "construction_paper" },
    { name: "Desktop Organizers", code: "desktop_organizers"},
  ];

  const return_properties = ['pid','title','description','thumb_image','url','sale_price','brand','crumbs','reviews','searchable_crumbs']

  //const filter_properties = ['brand','crumbs']
  const sort_properties = ['sale_price','reviews']


 // Empty dependency array - fetch data only on initial render


 const handleShowProduct = async(result) => {
  console.log("BUTTON CLICKED SUCCESSFULLY");
  const response = await axios.post(`${config.BASE_URL}/carousel`,
        {
          result : result,
          limit : parseInt(limit)
        }
      );
  console.log('the returned response is',response.data);
  }


  const handlePagination = useCallback((newPage) => {
    setCurrentPage(newPage);
    setStartIndex((newPage - 1) * pageSize);
    setEndIndex(Math.min(newPage * pageSize - 1, results.length)); // Handle last page with fewer items
}, [results, setCurrentPage, setStartIndex, setEndIndex, pageSize]);

useEffect(() => {
    if (results.length !== 0) {
        handlePagination(1); // Trigger pagination on initial results load
    }
}, [results, handlePagination]);


const translateText = async (text) => {
  try {
    const response = await axios.post('https://translate.googleapis.com/translate_a/single', null, {
      params: {
        client: 'gtx',
        sl: 'auto',
        tl: 'en',
        dt: 't',
        q: text,
      },
    });
    const translated = response.data[0][0][0];
    setQuery(translated);
  } catch (error) {
    console.error('Error translating text:', error);
  }
};


const handleVoiceSearch = () => {
  if ('webkitSpeechRecognition' in window) {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';
    setListening(true); // Start the animation
    recognition.start();

    recognition.onresult = (event) => {
      const spokenText = event.results[0][0].transcript;
      //setQuery(spokenText);
      setListening(false); // Stop the animation
      recognition.stop();
      fetchData(spokenText);
      translateText(spokenText); // Fetch data based on spoken query
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error', event);
      setListening(false); // Stop the animation on error
      recognition.stop();
    };
  } else {
    alert('Your browser does not support voice search.');
  }
};
 


  const handleCategoryClick = async (category_code) => {
    setResponseTime('')
    setIsLoading(true); 
    setQuery('')
    const startTime = Date.now()
    try{
      const response = await axios.post(`${config.BASE_URL}/c/${category_code}`,
        {
          limit : parseInt(limit),
          return_properties : return_properties
        }
      );
      const endTime = Date.now()
      setResponseTime(String(endTime - startTime))
      console.log(response)
      setResults(response.data)
      // resultSetter(response.data)
    }catch(error){
      console.log(error)
    }finally {
      setIsLoading(false); 
    }
  }


  const fetchData = async (searchQuery) => {
    setResponseTime('')
    setIsLoading(true); 
    const startTime = Date.now()
    try {
      // const response = await axios.post('http://localhost:8000/search', { query: searchQuery });
      const response = await axios.post(`${config.BASE_URL}/search/${searchQuery}`,
      { 
        // query: searchQuery ,
        limit : parseInt(limit),
        alpha : parseFloat(alpha),
        translationEnabled : processingEnabled,
        processingEnabled : processingEnabled,
        return_properties : return_properties,
        filter_properties : ['searchable_crumbs'],
        sort_properties : sort_properties
      });
      console.log(response)
      const endTime = Date.now()
      setResponseTime(String(endTime - startTime))
      setResults(response.data);
      // resultSetter(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle CORS errors or other network errors here
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Server responded with:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something else happened in making the request
        console.error('Error in request:', error.message);
      }
    } finally {
      setIsLoading(false); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query) return;
    await fetchData(query);
  };

  return (
    <Fragment>

        <Container sx={{marginTop : "10px", marginBottom : "10px"}}>

          {
            categoryList.map((category,index) => (
              <Button sx={{marginRight : "5px", marginTop : "5px", marginBottom  :"5px"}} key={index} onClick={() => handleCategoryClick(category.code)} variant="outlined" color='primary' >
                  {category.name}
              </Button>
            ))
          }

        </Container>

        <Container>
          <form onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                label="Search Query"
                variant="outlined"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                disabled={isLoading}
                placeholder="Enter your search query"
                fullWidth
                sx={{ width: '95%' }}
              />
              <button onClick={handleVoiceSearch} className={`voice-search-button ${listening ? 'listening' : ''}`} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <span role="img" aria-label="microphone" style={{ fontSize: '30px' ,color:'black' }}  > 🎙️</span>
              </button>
            
              </Stack>
              <Container sx={{ marginTop:"10px",marginBottom:"10px",marginLeft : "-25px" }}>
                <Grid container justifyContent="space-between" >
                  <Grid>
                    <TextField sx={{marginTop : "10px", marginRight : "10px"}}
                      label="limit"
                      size='small'
                      variant="outlined"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      disabled={isLoading}
                      placeholder="Enter your search query"
                    />
                    <TextField sx={{marginTop : "10px", marginRight : "10px"}}
                      label="alpha"
                      size='small'
                      variant="outlined"
                      value={alpha}
                      onChange={(e) => setAlpha(e.target.value)}
                      disabled={isLoading}
                      placeholder="Enter your search query"
                    />
                  </Grid>
                  
                  <Grid>
                    <Button onClick={() => setProcessingEnabled(!processingEnabled)} color='info' variant={processingEnabled ? 'contained' : 'outlined'} sx={{ marginTop:"10px",marginBottom:"10px",marginRight : "-50px" }}>
                        <b>{processingEnabled ? 'Mindmap enabled' : 'Mindmap disabled'}</b>
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <Stack>
              <Button type="submit" variant="contained" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Search'}
              </Button>
            </Stack>
          </form>
          { responseTime !== '' ?
            <>
              <Container sx={{marginLeft : "-25px", marginTop : "10px"}}>
                  <Grid container justifyContent="space-between">

                    <Stack>
                      <Grid>
                          <b>Response time : {responseTime} ms</b>
                      </Grid>
                      <Grid>
                          <b>Total results : {results.length}</b>
                      </Grid>
                    </Stack>

                    <Grid>
                      <Pagination count={Math.ceil(results.length / 10)} variant="outlined"
                        onChange={(event, value) => {handlePagination(value)}}
                        value={currentPage}
                        color='primary'
                        />
                    </Grid>
                    
                  </Grid>
              </Container>
              <Container sx={{margin : "40px"}}/>
            </>
            :
            <></>

          }
          
          {!isLoading ? 
          results.length > 0 && (
            <div className="results" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {results.slice(startIndex,endIndex).map((result) => (
                // <Stack  sx={{display:"flex",flexDirection:"column", justifyContent:"center" , alignItems:"center"}}>
                <Card key={result.uniqueId} sx={{ width: 345 ,border:"2px solid black",margin:"5px"}}>
                  <CardMedia
                    component="img"
                    height="auto"
                    image={result.thumb_image}
                    alt={result.title}
                  />
                  <Container sx={{margin : "40px"}}/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {result.title}
                    </Typography>
                    <Container sx={{margin : "30px"}}/>
                    <Grid container justifyContent="space-between" >

                      <Stack>
                        <Typography variant="body2" color="info">
                          ID: {result.pid}
                        </Typography>
                        <Typography variant="body2" color="info">
                          BRAND : {result.brand === '' ? 'None' : result.brand}
                        </Typography>
                        <Link href={result.url} underline="none"> Show Product </Link>
                        <button onClick={()=>handleShowProduct(result)}>Click for Product</button>
                       
                      </Stack>  
                      <Typography variant='h5' >
                        <b>
                        $ {result.sale_price}
                        </b>
                      </Typography>
                    </Grid>

                  </CardContent>
                  
                </Card>
                // </Stack>
              ))}
            </div>
          ) 
          : 
          <></>
          }
      </Container>
    </Fragment>
  );
};

export default App;
